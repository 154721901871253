import { graphql } from 'gatsby';
import React from 'react';
import QuoteLoadingPage from 'components/LoadingPages/QuoteLoading';
import { navigatePreservingQuote } from 'helpers/navigation';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import { CsMeta } from 'types/contentStack';

type LoadingQuoteProps = {
  data: {
    csInterstitialPage: {
      additional_drivers_quote_summary: {
        meta: CsMeta;
        heading: string;
        subheading: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csInterstitialPage {
      additional_drivers_quote_summary {
        meta {
          meta_title
        }
        heading
        subheading
      }
    }
  }
`;

const QuoteLoading: React.FC<LoadingQuoteProps> = ({
  data: {
    csInterstitialPage: { additional_drivers_quote_summary },
  },
}) => {
  usePageTracking(additional_drivers_quote_summary.meta.meta_title);

  return (
    <QuoteLoadingPage
      loadingQuoteContent={additional_drivers_quote_summary}
      moveNext={() =>
        navigatePreservingQuote(
          quoteAndBuyRoutes.quoteSummary,
          undefined,
          undefined,
          true
        )
      }
      moveBack={() => navigatePreservingQuote(quoteAndBuyRoutes.additionalDrivers)}
    />
  );
};

export default QuoteLoading;
