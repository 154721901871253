import useGenerateQuote from 'api/quote/useGenerateQuote';
import { navigate } from 'gatsby';
import React, { useCallback, useEffect, useReducer } from 'react';
import InterstitialPage from 'components/InterstitialPage';
import { renewalRoutes } from 'helpers/routingHelper';
import { useAdditionalDriversDetails } from 'state/formData/additionalDriversDetails';
import { CsMeta } from 'types/contentStack';

type QuoteLoadingProps = {
  loadingQuoteContent: {
    meta: CsMeta;
    heading: string;
    subheading: string;
  };
  moveNext: () => void;
  moveBack: () => void;
};

const navigateOnRenewalValidationFailure = (policyNumber: string): void => {
  navigate(renewalRoutes.aboutYourCar(policyNumber), {
    state: { hasMissingFieldsOnSubmission: true },
  });
};

const QuoteLoadingPage: React.FC<QuoteLoadingProps> = ({
  loadingQuoteContent: { heading, subheading, meta },
  moveNext,
  moveBack,
}) => {
  // Loading some data from the redux state to see if it is populated
  // If the redux state is empty it means user has refreshed on quote-loading
  // In this case they are redirected as the quote request will fail
  const detailsFromState = useAdditionalDriversDetails();
  const dataInState = detailsFromState[0].addAdditionalDrivers !== undefined;
  const [requestStarted, setRequestStarted] = useReducer(() => true, false);
  const { generateQuote } = useGenerateQuote(navigateOnRenewalValidationFailure);

  const fetchQuoteAndMoveNext = useCallback(async (): Promise<void> => {
    const newQuote = await generateQuote();
    if (newQuote) {
      moveNext();
    }
  }, [generateQuote, moveNext]);

  useEffect(() => {
    if (!dataInState) {
      moveBack();
      return;
    }

    if (!requestStarted && dataInState) {
      setRequestStarted();
      fetchQuoteAndMoveNext();
    }
  }, [fetchQuoteAndMoveNext, requestStarted, dataInState, moveBack]);

  return (
    <InterstitialPage
      heading={heading}
      subheading={subheading}
      meta={meta}
      data-cy="QuoteGeneratingInterstitialPage"
    />
  );
};

export default QuoteLoadingPage;
